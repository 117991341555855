<template>
  <div>
    <img class="pt-8" src="/img/404.png" />
  </div>
</template>

<script>
  export default {
    name: 'NotFound',
  };
</script>

<style scoped>
  img {
    display: block;
    margin: 0 auto;
    max-width: 90%;
  }
</style>
